import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { URL, initialValues, pages } from "../utils/seo";

interface SEOProps {
  children: React.ReactNode;
}

const SEO: React.FC<SEOProps> = ({ children }) => {
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language || "en";

  const location = useLocation();
  const route = location.pathname;

  const { initial_title, initial_description, initial_image } = initialValues;

  const [title, setTitle] = useState(initial_title);
  const [description, setDescription] = useState(initial_description);
  const [image, setImage] = useState(`${URL}${initial_image}`);
  const [canonicalUrl, setCanonicalUrl] = useState(`${URL}${route}`);

  useEffect(() => {
    let page = pages.find((page) => page.route === route);

    let _title =  page?.title || initial_title;
    let _description = page?.description || initial_description;
    let _image = page?.image || initial_image;

    setTitle(_title);
    setDescription(_description);
    setImage(`${URL}${_image}`);
    setCanonicalUrl(`${URL}${route}`);
  }, 
  [route, currentLanguage]);

  return (
    <>
    <Helmet>
      <title>{t(`SEO.${title}`)}</title>
      <meta name="description" content={t(`SEO.${description}`)} />

      <meta property="og:title" content={t(`SEO.${title}`)} />
      <meta property="og:description" content={t(`SEO.${description}`)} />
      <meta property="og:image" content={image} />

      <link rel="canonical" href={canonicalUrl} />

      <link rel="alternate" hrefLang="x-default" href={URL} />
      <link rel="alternate" hrefLang="en" href={URL} />
      <link rel="alternate" hrefLang="ru" href={`${URL}/ru/`} />
      <link rel="alternate" hrefLang="sr" href={`${URL}/sr/`} />
    </Helmet>
    {children}
    </>
  );
};

export default SEO;
