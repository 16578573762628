import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './assets/locales/en/translations.json';
import ruTranslation from './assets/locales/ru/translations.json';
import srTranslation from './assets/locales/sr/translations.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    supportedLngs: ['en', 'ru', 'sr'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
      caches: ['cookie'],
    },
    resources: {
        en: {
          translation: enTranslation,
        },
        ru: {
           translation: ruTranslation,
        },
        sr: {
          translation: srTranslation, 
      },
      },
  });

// Listen for the language change and update the lang tag of the <html> element
i18n.on('languageChanged', (lng) => {
  if(lng === "sr"){
    document.documentElement.lang = "sr-latn";
  } else {
    document.documentElement.lang = lng;
  }
});

export default i18n;